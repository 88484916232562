<template>
  <b-card>
    <div class="row">
      <div class="col-md-6">
        <b-form-group :label="$t('ai-ide-module.stable-diffusion.model')">
          <v-select
            v-model="modelName"
            :clearable="false"
            label="label"
            :options="ARRAY_UNIQUE_BY_KEY(modelList, 'value')"
            :reduce="option => option.value"
            class="w-100"
          />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <b-form-group :label="$t('ai-ide-module.stable-diffusion.artist')">
          <v-select
            v-model="artistFilter"
            :clearable="false"
            label="label"
            :options="artistList"
            multiple
            :reduce="option => option.value"
            class="w-100"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group :label="$t('ai-ide-module.stable-diffusion.modifier')">
          <v-select
            v-model="modifierFilter"
            :clearable="false"
            label="label"
            :options="modifierList"
            multiple
            :reduce="option => option.value"
            class="w-100"
          />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-tabs>
          <b-tab
            v-if="['txt2img', 'sdxl-base'].includes(modelName)"
            title="Style"
          >
            <div class="row align-items-center mx-1">
              <span v-for="style, styleIndex in monsterStyles"
                    :key="styleIndex"
                    class="col-md-4 d-flex align-items-center"
              >
                <b-form-checkbox
                  v-model="monsterAiStyle"
                  type="checkbox"
                  class="form-check-input"
                  :value="style"
                  style="width:20px; height:20px"
                />
                <label :for="style"
                       class="form-check-label text-capitalize"
                       style="margin-left:7px"
                >
                  {{ style }}
                </label>
              </span>
            </div>
          </b-tab>
          <template v-else>
            <b-tab v-for="category, index in categoryGroups"
                   :key="index"
                   :title="category.name"
            >
              <b-tabs vertical
                      nav-wrapper-class="nav-vertical"
              >
                <b-tab v-for="item, itemIndex in category.items"
                       :key="itemIndex"
                       :title="item.type"
                >
                  <div class="row align-items-center">
                    <span v-for="option, optionIndex in item.options"
                          :key="optionIndex"
                          class="col-md-4 d-flex align-items-center"
                    >
                      <b-form-checkbox
                        v-model="modifierFilter"
                        type="checkbox"
                        class="form-check-input"
                        :value="option"
                        style="width:20px; height:20px"
                      />
                      <label :for="option"
                             class="form-check-label"
                             style="margin-left:7px"
                      >
                        {{ option }}
                      </label>
                    </span>
                  </div>
                </b-tab>
              </b-tabs>
            </b-tab>
          </template>

        </b-tabs>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <b-form-group :label="$t('ai-ide-module.stable-diffusion.prompt-text')">
          <b-form-textarea v-model="imgPrompt" />
        </b-form-group>
      </div>
      <div class="col-md-12">
        <b-form-group :label="$t('ai-ide-module.stable-diffusion.negative-prompt')">
          <b-form-textarea v-model="negativePrompt" />
        </b-form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-md-5">
        <b-button :disabled="state.isProcessing"
                  variant="outline-primary"
                  @click="setupStableImage"
        >
          <b-spinner v-show="state.isProcessing"
                     small
          /> {{ $t('ai-ide-module.stable-diffusion.generate-image') }}
        </b-button>
        <b-button v-if="state.isProcessing"
                  variant="outline-primary"
                  class="ml-1"
                  @click="resetImageGeneration"
        >
          {{ $t('actions.cancel') }}
        </b-button>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-md-12">
        <b-overlay :show="state.isQueueRunning"
                   style="min-height: 150px;"
        >
          <img v-if="generatedImage"
               v-image-preview
               :src="generatedImage"
          >
        </b-overlay>
      </div>
    </div>
  </b-card>
</template>
<script setup>
import {
  BFormGroup, BFormTextarea, BCard, BButton, BSpinner, BOverlay, BTabs, BTab, BFormCheckbox,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { onMounted } from 'vue'
import {
  modelList, artistStyle, categoryGroups, monsterStyles,
} from '@/const/modelList'
import useDiffusion from './useDiffusion'

const props = defineProps({
  word: {
    type: String,
    default: '',
  },
})
const emits = defineEmits([
  'onImageGenerate',
])
const {
  // setupImage,
  setupStableImage,
  state,
  generatedImage,
  imgPrompt,
  modelName,
  artistFilter,
  modifierFilter,
  negativePrompt,
  monsterAiStyle,
  resetImageGeneration,
} = useDiffusion({ emits })

onMounted(() => {
  imgPrompt.value = props.word
})

const artistList = []
artistStyle.forEach(option => {
  artistList.push({
    value: option.artist,
    label: `${option.artist} -- ${option.style}`,
  })
})
const modifierList = []

categoryGroups.forEach(group => {
  group.items.forEach(item => {
    item.options.forEach(option => {
      modifierList.push({
        value: option,
        label: option,
      })
    })
  })
})
</script>
